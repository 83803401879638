import React, { Component } from 'react'
import { Link } from 'gatsby'
import './subnavbar.css'
import { useStaticQuery, graphql } from 'gatsby'
import * as lodash from 'lodash'
const query = graphql`
  {
    allStrapiSubnavbars(sort: { order: ASC, fields: order }) {
      nodes {
        bg_mega_menu {
          color
        }
        bg_sub_navbar {
          color
        }
        menu_hover_color {
          color
        }
        menu_item {
          order
          menu_url
          menu_name
          submenu_name {
            font_color {
              color
            }
            font_family {
              family
            }
            font_size {
              size
            }
            font_weight {
              weight
            }
            menu_name
            url
          }
        }
      }
    }
  }
`

const SubNavbar = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiSubnavbars: { nodes: subnavbar },
  } = data

  const {
    bg_mega_menu,
    menu_hover_color,
    bg_sub_navbar,
    menu_item,
  } = subnavbar[0]
  const subnavbarData = subnavbar[0].menu_item
  const subnavbarDatas = lodash.orderBy(subnavbarData, ['order'], ['asc'])
  return (
    <header className="dark">
      <nav
        style={{
          backgroundColor: bg_sub_navbar.color,
        }}
        role="navigation"
      >
        <a className="ic menu" tabIndex={1}>
          <span className="line" />
          <span className="line" />
          <span className="line" />
        </a>
        <a className="ic close" />
        <ul className="main-nav">
          {subnavbarDatas.map((document, index1) => {
            const menuName = document.menu_name

            // if (typeof window !== 'undefined') {
            const storedData =
              typeof window !== 'undefined'
                ? localStorage.getItem('token')
                : null
            if (
              (storedData === 'undefined' || !storedData) &&
              menuName === 'Members Only'
            ) {
              return ''
            }
            //}

            return (
              <li className="top-level-link" key={index1}>
                <Link to={`/${document.menu_url}`}>
                  <a>
                    <span>{menuName}</span>
                  </a>
                </Link>
                {document.submenu_name[0] && (
                  <div
                    className="sub-menu-block"
                    style={{ backgroundColor: bg_mega_menu.color }}
                  >
                    {document.submenu_name && (
                      <div>
                        <ul className="sub-menu-lists">
                          {document.submenu_name.map((menu, index) => (
                            <li key={index}>
                              <Link to={`/${menu.url}`}>
                                <a
                                  style={{
                                    color: menu.font_color.color,
                                  }}
                                >
                                  <span>{menu.menu_name}</span>
                                </a>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </nav>
    </header>
  )
}

export default SubNavbar
