import React from 'react'
import Image from 'gatsby-image'
import { Link } from 'gatsby'

const SponsorPage = ({ id, logo, logo_height, logo_width, sponsor_URL }) => {
  return (
    <div>
      {logo && logo.childImageSharp && (
        <Link to={sponsor_URL} target="_blank" key={id}>
          <Image
            style={{
              width: logo_width || '250px',
              height: logo_height || '70px',
            }}
            fluid={logo.childImageSharp.fluid}
            className="img-fluid"
          />
        </Link>
      )}
    </div>
  )
}

export default SponsorPage
