import React, { Component } from 'react'
import { MDBCol, MDBRow, MDBFooter, MDBIcon } from 'mdbreact'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  {
    allStrapiFooters {
      nodes {
        bg_color {
          color
          id
        }
        menu {
          id
          font_stack
        }
        font_color {
          color
          id
        }
        font_family {
          family
          id
        }
        font_size {
          size
          id
        }
        menu {
          font_stack
          id
        }
        title
        footer_height
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiFooters: { nodes: footer },
  } = data
  const {
    bg_color,
    font_color,
    font_family,
    font_size,
    menu,
    title,
    footer_height,
  } = footer[0]
  return (
    <MDBFooter
      style={{
        backgroundColor: bg_color.color,
        height: footer_height,
      }}
      className="font-small pt-0"
    >
      <MDBCol md="12">
        <div className="mb-5 text-center-content">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fisridstrapi.com%2F"
            target="_blank"
          >
            <MDBIcon
              far
              icon="share-square"
              size="lg"
              className="mx-2 white-text mr-md-4"
            >
              Share this ISRID page in your facebook wall
            </MDBIcon>
          </a>
          <a
            href="https://www.facebook.com/Iowa-State-RID-109400462528/#blank"
            target="_blank"
          >
            <MDBIcon
              fab
              icon="facebook"
              size="lg"
              className="mx-2 white-text mr-md-4"
            >
              Our facebook page
            </MDBIcon>
          </a>
        </div>
      </MDBCol>
    </MDBFooter>
  )
}

export default Footer
