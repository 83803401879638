import React, { useState, useEffect } from 'react'

import { Link } from 'gatsby'
import './subnavbar.css'
import { useStaticQuery, graphql } from 'gatsby'
import * as lodash from 'lodash'

import Img from 'gatsby-image'

import Login from '../pages/login'

const query = graphql`
  {
    allStrapiLogins {
      nodes {
        id
        height
        login_content {
          width
          url
          path
          function_name
          login_content
          order
          content_id
          bg_color {
            color
            id
          }
          float
          font_family {
            family
            id
          }
          font_color {
            color
            id
          }
          font_size {
            size
            id
          }
          font_weight {
            weight
            id
          }
        }
      }
    }
    allStrapiBanners {
      nodes {
         ImageLink
      }
    }
  }
`
const TopNavbarPage = () => {
  const [loginModal, setLoginModal] = useState(true)
  const [viewModal, setViewModal] = useState(false)

  useEffect(() => {}, [])

  const data = useStaticQuery(query)
  console.log(data)

  const {
    allStrapiLogins: { nodes: login },
  } = data

  console.log('loginDaa', data)

  const loginData = login[0].login_content
  const LoginDatas = lodash.orderBy(loginData, ['order'], ['asc'])

  const { height } = login[0]

  const menuHide = getMenuHide()

  const loginResponse = LoginDatas.filter(item => {
    return menuHide != item.content_id
  })

  function getMenuHide() {
    const storedData =
      typeof window !== 'undefined' ? localStorage.getItem('token') : null
    if (storedData === 'undefined' || !storedData) {
      return 'LOGOUT'
    } else {
      //item.url = '#';
      return 'LOGIN'
    }
  }
  
    const ImageLink = "https://isrid.testsitemegarcus.com" + data.allStrapiBanners.nodes[0].ImageLink


  return (
    <nav>
      <ul style={{listStyleType:"none",backgroundColor:"rgb(247 208 0)"}} className="top-nav">
        <li className="top-level-link">
        {/* <Img fixed={data.allStrapiBanners.nodes[0].Image.childImageSharp.fixed}/> */}
          <img src={ImageLink} className="ImageLink" alt="TriImage" />
         {loginResponse.map((item, index1) => {
          return (
            <li
              className="top-level-link"
              key={index1}
              style={{
                width: item.width || '100%',
                backgroundColor: item.bg_color.color,
                textAlign: item.float,
                height: height || '30px',
              }}
            >
               
              <Link to={'#'} onClick={() => loginFun(item)}>
                <span
                  style={{
                    color: item.font_color.color,
                    fontSize: item.font_size.size,
                    fontFamily: item.font_family.family,
                    fontWeight: item.font_weight.weight,
                  }}
                >
                  {item.login_content}{' '}
                </span>{' '}
              </Link>{' '}
            </li>
          )
        })}{' '}
        </li>
         
      </ul>
        
      {viewModal && <Login loginModal={loginModal} />}{' '}
    </nav>
  )

  function loginFun(item) {
    // debugger
    if (item.content_id === 'LOGIN') {
      setViewModal(true)
      setLoginModal(!loginModal)
    } else if (item.content_id === 'LOGOUT') {
      logoutFun()
    } else {
      if (typeof window !== 'undefined') {
        window.location.pathname = item.path
      }
    }
  }

  function logoutFun() {
    if (typeof window !== 'undefined') {
      localStorage.clear()
      window.location.reload()
    }
  }
}

function getPageURL(item) {
  if (item.content_id === 'LOGOUT') return '/logout'
  else if (item.content_id === 'LOGIN') return `/${item.url}`
  else return '/home'
}

export default TopNavbarPage
