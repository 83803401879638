import React, { useState, useEffect } from 'react'
import SponsorNew from './sponsorsNew'

import PropTypes from 'prop-types'
import Navbar from './navbar'
import Footer from './footer'
import './layout.css'
import SubNavbar from './subnavbar'
import TopNavbar from './topbar'

import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

function Layout({ children, location, crumbLabel }) {

  console.log('location', location)
  console.log('crumbLabel', crumbLabel)
  return (
    
    <>
      <TopNavbar />
      <Navbar />
      <SubNavbar />
      <main style={{ backgroundColor: '#FFF' }}>
        {' '}
        
        {/*location && crumbLabel && (
          <Breadcrumb location={location} crumbLabel={crumbLabel} />
        )*/}{' '}
        {children}{' '}
      </main>{' '}
      <SponsorNew />
      <Footer />
    </>
  

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
