import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useStaticQuery, graphql } from 'gatsby'
import SponsorPage from './Sponsorcarousel'
import { MDBRow } from 'mdbreact'

const query = graphql`
  {
    allStrapiSponsors {
      nodes {
        Sponor_title
        font_color {
          color
        }
        font_family {
          family
        }
        font_size {
          size
        }
        font_weight {
          weight
        }
        sponsor {
          logo_height
          logo_width
          sponsor_URL
          sponsor_title
          logo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

//https://www.npmjs.com/package/react-multi-carousel : For Reference
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

const SponsorNew = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiSponsors: { nodes: splist },
  } = data
  const {
    Sponor_title,
    font_color,
    font_family,
    font_size,
    font_weight,
    sponsor_URL,
    sponsor,
  } = splist[0]
  console.log(splist)
  return (
    <div className="text-center">
      <h1
        style={{
          color: font_color.color,
          fontFamily: font_family.family,
          fontSize: font_size.size,
          fontWeight: font_weight.weight,
        }}
      >
        {Sponor_title}
      </h1>
      <div>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={5000}
          itemClass="carousel-item-padding-10-px"
          autoPlay={true}
        >
          {sponsor.map((splists, index) => {
            return <SponsorPage key={index} {...splists} />
          })}
        </Carousel>
      </div>
    </div>
  )
}

export default SponsorNew
