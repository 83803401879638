import React, { Component } from 'react'

import { Link } from 'gatsby'
import './navbar.css'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const query = graphql`
  {
    allStrapiNavbars {
      nodes {
        bg_color {
          id
          color
        }
        title
        logo_width
        header_height
        logo_height
        logo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
const NavbarPage = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiNavbars: { nodes: navabar },
  } = data
  const { bg_color, logo_width, header_height, logo, logo_height } = navabar[0]
  return (
    <nav
      className="navigation"
      style={{
        backgroundColor: bg_color.color,
        height: header_height,
      }}
    >
      <Link to="/">
        <Image
          fluid={logo.childImageSharp.fluid}
          className="logo-img"
          style={{ width: logo_width, height: logo_height }}
        />
      </Link>
    </nav>
  )
}

export default NavbarPage
